// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-history-index-tsx": () => import("./../../../src/pages/live/history/index.tsx" /* webpackChunkName: "component---src-pages-live-history-index-tsx" */),
  "component---src-pages-live-index-tsx": () => import("./../../../src/pages/live/index.tsx" /* webpackChunkName: "component---src-pages-live-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-website-index-tsx": () => import("./../../../src/pages/website/index.tsx" /* webpackChunkName: "component---src-pages-website-index-tsx" */),
  "component---src-templates-live-entry-tsx": () => import("./../../../src/templates/live-entry.tsx" /* webpackChunkName: "component---src-templates-live-entry-tsx" */)
}

